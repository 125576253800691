<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Aprovação de pagamento</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon color="red">mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12">

                  <v-textarea
                  outlined
                  name="input-7-4"
                  label="Observação"
                  v-model="observacao"
                  :rules="[rules.required, rules.Max200CaracterLimit]"
                  counter="200"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
        </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
          v-if="statusPagamento == 'N' || statusPagamento == '' ? true : false"
          color="success darken-1"
          dark
          @click="handleUpdateAprovacaoStatus('S')"
          >
            Aprovar
          </v-btn>
          <v-btn
          v-if="statusPagamento == 'S' || statusPagamento == '' ? true : false"
          color="error darken-1"
          dark
          @click="handleUpdateAprovacaoStatus('N')"
          >
          Reprovar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import rules from "@/mixins/inputRules";
import campanhaService from "@/services/campanha";

export default {
  name: "ApprovePaymentModal",
  mixins: [rules],

  props: {
    idCampanha: String,
    dialog: {
      type: Boolean,
      default: false
    },
    statusPagamento: {
      type: String,
      default: ""
    }
  },

  data: () => ({
    observacao: "",
    messages: {
      S: "Pagamento Aprovado!",
      N: "Pagamento Reprovado!"
    }
  }),

  methods: {
    close() {
      this.$emit("closeModal");
    },

    async handleUpdateAprovacaoStatus(status) {
      try {
        const validateStatus = this.$refs.form.validate();
        if (validateStatus) {
          await campanhaService.postAprovacaoDePagamentoCampanha(
            this.idCampanha,
            {
              observacao: this.observacao,
              status
            }
          );
          this.close();
          this.$notify({
            text: this.messages[status],
            type: "success"
          });
        }
      } catch (error) {
        this.$notify({
          text: "Erro ao Julgar o pagamento da campanha",
          type: "error"
        });
      }
    }
  }
};
</script>

<style></style>
