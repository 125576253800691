var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"grid-list-xs":""}},[_c('v-menu',{attrs:{"offset-X":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-file-pdf")]),_vm._v(" Relatórios ")],1)]}}])},[_c('v-list',_vm._l((_vm.items),function(item,index){return _c('v-list-item',{key:index,on:{"click":item['method']}},[_c('v-list-item-title',{staticClass:"mouser-pointer"},[_vm._v(_vm._s(item.title))])],1)}),1)],1),_c('RelatorioXlsx',{staticClass:"ml-5",attrs:{"filter":{ id_campanha: _vm.$route.params.idCampanha },"apuracaoGeral":"","fileName":_vm.apuracao.title,"label":"Geral"}}),(
      _vm.aprovacaoPagamento.status === 'S' || _vm.aprovacaoPagamento.status === 'N'
    )?_c('RelatorioXlsx',{staticClass:"ml-5",attrs:{"filter":{ id_campanha: _vm.$route.params.idCampanha },"disabled":_vm.aprovacaoPagamento.status === 'N',"apuracaoInfinite":"","fileName":_vm.apuracao.title,"label":"Infinite"}}):_vm._e(),_c('v-btn',{directives:[{name:"can-access",rawName:"v-can-access",value:(361),expression:"361"}],staticClass:"ml-5",attrs:{"color":Object.keys(_vm.aprovacaoPagamento).length != 0 ? _vm.aprovedStyle[_vm.aprovacaoPagamento.status].color : 'warning',"dark":""},on:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-cash-remove")]),_vm._v(" "+_vm._s(Object.keys(_vm.aprovacaoPagamento).length != 0 ? _vm.aprovedStyle[_vm.aprovacaoPagamento.status].text : "Aprovar/Desaprovar Pagamento")+" ")],1),(_vm.aprovacaoPagamento.status === 'N')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-alert")])],1)]}}],null,false,2874980651)},[_c('span',[_vm._v(_vm._s(_vm.aprovacaoPagamento.observacao))])]):_vm._e(),_c('ApprovePaymentModal',{attrs:{"idCampanha":_vm.$route.params.idCampanha,"dialog":_vm.dialog,"statusPagamento":_vm.aprovacaoPagamento.status},on:{"closeModal":_vm.closeModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }