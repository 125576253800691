<template>
  <v-container grid-list-xs>
    <v-menu offset-X>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          <v-icon class="mr-1">mdi-file-pdf</v-icon>
          Relatórios
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          @click="item['method']"
        >
          <v-list-item-title class="mouser-pointer">{{
            item.title
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <RelatorioXlsx
      :filter="{ id_campanha: $route.params.idCampanha }"
      apuracaoGeral
      :fileName="apuracao.title"
      label="Geral"
      class="ml-5"
    />

    <RelatorioXlsx
      v-if="
        aprovacaoPagamento.status === 'S' || aprovacaoPagamento.status === 'N'
      "
      :filter="{ id_campanha: $route.params.idCampanha }"
      :disabled="aprovacaoPagamento.status === 'N'"
      apuracaoInfinite
      :fileName="apuracao.title"
      label="Infinite"
      class="ml-5"
    />

    <v-btn
      v-can-access="361"
      class="ml-5"
      :color="Object.keys(aprovacaoPagamento).length != 0 ? aprovedStyle[aprovacaoPagamento.status].color : 'warning'"
      @click="dialog = true"
      dark
    >
      <v-icon class="mr-1">mdi-cash-remove</v-icon>
      {{Object.keys(aprovacaoPagamento).length != 0 ? aprovedStyle[aprovacaoPagamento.status].text : "Aprovar/Desaprovar Pagamento"}}
    </v-btn>

    <v-tooltip top v-if="aprovacaoPagamento.status === 'N'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="error" v-bind="attrs" v-on="on">
          <v-icon class="mr-1">mdi-alert</v-icon>
        </v-btn>
      </template>
      <span>{{ aprovacaoPagamento.observacao }}</span>
    </v-tooltip>

    <ApprovePaymentModal
      :idCampanha="$route.params.idCampanha"
      :dialog="dialog"
      :statusPagamento="aprovacaoPagamento.status"
      @closeModal="closeModal"
    />
  </v-container>
</template>
<script>
import ApprovePaymentModal from "./ApprovePaymentModal";
import RelatorioXlsx from "../RelatorioXlsx";
import campanhaService from "@/services/campanha";
import jasperService from "@/services/http/jasperService";

import { mapState, mapGetters } from "vuex";

export default {
  components: {
    RelatorioXlsx,
    ApprovePaymentModal
  },

  data() {
    return {
      dialog: false,
      aprovacaoPagamento: {},
      aprovedStyle: {
        "N": {
          color: "green",
          text: "Aprovar Pagamento"
        },
        "S": {
          color: "red",
          text: "Reprovar Pagamento"
        }
      },
      items: [
        {
          title: "Resumo Geral",
          method: this.getResumoCampanha
        },
        {
          title: "Resumo por Ganhadores",
          method: this.getResumoGanhou
        },
        {
          title: "Resumo por Indicador",
          method: this.getResumoIndicador
        },
        {
          title: "Meta Campanha",
          method: this.getMetaCampanha
        }
      ]
    };
  },
  computed: {
    ...mapState("campanha", {
      apuracaoFilters: state => state.apuracao.filters
    }),

    ...mapGetters({
      apuracao: "campanha/getApuracao"
    })
  },

  mounted() {
    this.showAprovacaoPagamento();
  },

  methods: {
    closeModal() {
      this.dialog = false;
      this.showAprovacaoPagamento();
    },

    async showAprovacaoPagamento() {
      if (this.permissionTypes.number(361)) {
        try {
          const {
            data
          } = await campanhaService.showAprovacaoDePagamentoCampanha(
            this.$route.params.idCampanha
          );
          this.aprovacaoPagamento = data; 
        } catch (error) {
          this.$notify({
            text: "Erro ao buscar a informaçãp de pagamento da campanha",
            type: "error"
          });
        }
      }
    },

    async getResumoCampanha() {
      const filters = {
        in_id_campanha: this.$route.params.idCampanha
      };

      let { data } = await jasperService()
        .campanha()
        .resumoCampanha()
        .show(filters);
      this.generatePdf(data);
    },

    async getResumoIndicador() {
      const filters = {
        in_id_campanha: this.$route.params.idCampanha
      };

      let { data } = await jasperService()
        .campanha()
        .resumoIndicador()
        .show(filters);
      this.generatePdf(data);
    },

    async getResumoGanhou() {
      const filters = {
        in_id_campanha: this.$route.params.idCampanha,
        in_ganhou: this.apuracaoFilters.ganhou || ""
      };

      let { data } = await jasperService()
        .campanha()
        .resumoGanhouNaoGanhou()
        .show(filters);
      this.generatePdf(data);
    },

    async getMetaCampanha() {
      const filters = {
        in_id_campanha: this.$route.params.idCampanha
      };

      let { data } = await jasperService()
        .campanha()
        .metaCampanha()
        .show(filters);
      this.generatePdf(data);
    },

    generatePdf(data) {
      var blob = new Blob([data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    }
  }
};
</script>
<style scoped>
.mouser-pointer {
  cursor: pointer;
}

.v-list-item:hover {
  background-color: silver;
}

.v-tooltip__content {
  max-width: 25% !important;
}
</style>
